<template>
  <div class="access-secret-alert z-index-top" v-if="isShow">
    <div class="access-secret-alert-card">
      <div class="access-secret-alert-card-top">
        <p class="access-secret-alert-card-top-title">access-secret</p>
        <button
          class="access-secret-alert-card-top-close"
          @click="onCloseClick"
          :style="{
            backgroundImage: `url(${require('@imgs/access-secret-alert-close.png')})`,
          }"
        ></button>
      </div>
      <div class="access-secret-alert-card-middle">
        <p class="access-secret-alert-card-middle-secret">{{ accessSecret }}</p>
        <button
          class="access-secret-alert-card-middle-copy"
          v-clipboard:copy="accessSecret"
          v-clipboard:success="onCopySucceed"
          v-clipboard:error="onCopyFailed"
        >
          复制
        </button>
      </div>
      <p class="access-secret-alert-card-bottom">注意！请及时保存AppSecret！</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isShow: false,
    accessSecret: String,
  },

  methods: {
    onCloseClick() {
      this.$emit("onCloseClick");
    },

    onCopySucceed() {
      this.$toast.showGreen(MSG.copySucceed);
    },

    onCopyFailed() {
      this.$toast.showRed(MSG.copyFailed);
    },
  },
};
</script>

<style lang="scss" scoped>

.access-secret-alert {
  background-color: rgba($color: #666666, $alpha: 0.2);
  position: fixed;
  top: rem(0);
  left: rem(0);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &-card {
    background-color: white;
    border-radius: rem(3);
    box-shadow: rem(0) rem(0) rem(15) rem(0) rgba(black, 0.1);
    width: rem(384);
    height: rem(120);
    box-sizing: border-box;
    padding: rem(17) rem(24);

    &-top {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-title {
        color: #4d4f5c;
        font-size: rem(16);
        line-height: rem(22);
      }

      &-close {
        background-size: rem(16) rem(16);
        background-repeat: no-repeat;
        width: rem(16);
        height: rem(16);
      }
    }

    &-middle {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: rem(14);

      &-secret {
        color: #666666;
        font-size: rem(14);
        line-height: rem(20);
      }

      &-copy {
        color: #325FAC;
        font-size: rem(14);
        line-height: rem(20);
      }
    }

    &-bottom {
      color: #c1c1c1;
      font-size: rem(14);
      line-height: rem(20);
      margin-top: rem(14);
    }
  }
}
</style>