<template>
  <div class="develop-configuration">
    <p class="develop-configuration-developer">开发者</p>
    <div class="develop-configuration-content">
      <p class="develop-configuration-content-access-key">
        access-key：<span class="develop-configuration-content-access-key-value">{{ accessKeyValueStr }}</span>
      </p>
      <div class="develop-configuration-content-right">
        <p class="develop-configuration-content-right-access-secret">
          access-secret：
          <span class="develop-configuration-content-access-secret-value">{{ accessSecretValueStr }}</span>
        </p>
        <button class="develop-configuration-content-right-apply" @click="onApplyClick">
          {{ buttonTitle }}
        </button>
      </div>
    </div>
    <p class="develop-configuration-document">开发文档</p>
    <p class="develop-configuration-document-tip">请联系客户经理获取！</p>
    <access-secret-alert :isShow="isShowAccessSecretAlert" :accessSecret="accessSecret"
      @onCloseClick="onAccessSecretAlertCloseClick"></access-secret-alert>
  </div>
</template>

<script>
import AccessSecretAlert from "@c/setting/develop-configuration/access-secret-alert.vue";

export default {
  components: {
    AccessSecretAlert,
  },

  data() {
    return {
      name: "organization",
      accessInfo: undefined,
      isShowAccessSecretAlert: false,
    };
  },

  computed: {
    hospitalID: function () {
      return this.$store.state.selectedHospital.hospitalid;
    },

    accessKey() {
      return this.accessInfo?.accessKey
    },

    accessSecret() {
      return this.accessInfo?.accessValue
    },

    accessKeyValueStr() {
      return this.accessKey ? this.accessKey : "未生成"
    },

    accessSecretValueStr() {
      return this.accessKey ? "已生成" : "未生成"

    },

    buttonTitle() {
      return this.accessKey ? "重新生成" : "点击申请"
    }

  },

  created() {
    this.getInfo();
  },

  watch: {
    hospitalID() {
      this.getInfo();
    },
  },

  methods: {
    //actions
    onApplyClick() {
      this.$emit("onApplyClick")
    },

    onAccessSecretAlertCloseClick() {
      this.isShowAccessSecretAlert = false;
    },

    //methods
    async getInfo() {
      try {
        const params = {
          "hospitalId": this.hospitalID
        };
        const data = await this.$api.getDevelopConfig(params);
        this.accessInfo = data;
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    async handleApply() {
      try {
        //更新accessInfo（没有key获得key和value；有key更新value）
        const refreshParams = {
          "hospitalId": this.hospitalID
        };
        const data = await this.$api.accessFresh(refreshParams);
        //保存
        const editParams = {
          "hospitalId": this.hospitalID,
          "accessKey": data.accessKey,
          "accessValue": data.accessValue
        };
        await this.$api.editDevelopConfig(editParams);
        //显示
        this.accessInfo = data
        this.isShowAccessSecretAlert = true;
      } catch (error) {
        this.$toast.showRed(error);
      }
    }
  },
};
</script>

<style lang="scss" scoped>


.develop-configuration {
  background-color: white;
  border-radius: rem(2);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: rem(87) rem(366) rem(0) rem(36);

  &-developer {
    color: #000000;
    font-size: rem(16);
    font-weight: 500;
    line-height: rem(22);
  }

  &-content {
    display: flex;
    justify-content: space-between;
    margin-top: rem(18);

    &-access-key {
      color: #000000;
      font-size: rem(14);
      line-height: rem(20);

      &-value {
        margin-left: rem(13);
      }
    }

    &-right {
      display: flex;
      align-items: center;

      &-access-secret {
        color: #000000;
        font-size: rem(14);
        line-height: rem(20);

        &-value {
          margin-left: rem(13);
        }
      }

      &-apply {
        color: #325FAC;
        font-size: rem(14);
        border-bottom: rem(1) solid #325FAC;
        margin-left: rem(60);
      }
    }
  }

  &-document {
    color: #000000;
    font-size: rem(16);
    line-height: rem(22);
    margin-top: rem(26);

    &-tip {
      color: #000000;
      font-size: rem(14);
      line-height: rem(20);
      margin-top: rem(18);
    }
  }
}
</style>